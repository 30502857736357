<template>
    <div>
        <ViewTitle title="業績報表 | 競賽業績查詢">
            <template #buttons v-if="hasDownloadAllPermission">
                <Button
                    :disabled="allCompanyReportState === 'loading'"
                    @click="downloadAllCompanyInfo"
                    :buttonWord="allCompanyReportWord"
                    :iconClass="allCompanyReportStyle"
                    buttonStyle="blue-hollow"
                />
            </template>
        </ViewTitle>
        <ContestFilter
            :isLoading="isTableLoading"
            @show-data-table="showDataTable"
            v-model:filterPayloadProp="filterPayload"
            v-model:filterLogProp="filterLog"
            v-model:contestInfo="contestInfo"
        />
        <SearchCondition
            :dataList="searchConditionList"
            :isShowTable="true"
            :isHasListData="listData.length > 0"
            :isLoading="isTableLoading"
        />
        <ContestTable
            :listData="listData"
            :contestInfo="contestInfo"
            :filterLog="filterLog"
            :isLoading="isTableLoading"
            :isSelf="isSelf"
            :isCeoMode="isCeoMode"
            :infos="[...searchConditionList]"
        />
    </div>
</template>

<script>
// import _ from 'lodash'
import ViewTitle from '@/components/ViewTitle.vue'
import SearchCondition from '@/components/SearchCondition.vue'
import ContestFilter from '@/containers/report/ContestFilter.vue'
import ContestTable from '@/containers/report/ContestTable.vue'
import Button from '@/components/Button.vue'
import {
    getReportContestPersonalAPI,
    getReportContestOrgAPI,
    postBackstageGenerateReportAPI,
    getBackstageReportInfosAPI,
    getBackstageDownloadReportAPI
} from '@/assets/javascripts/api'

export default {
    name: 'Contest',
    components: {
        ViewTitle,
        SearchCondition,
        ContestFilter,
        ContestTable,
        Button
    },

    methods: {
        showDataTable: async function () {
            if (!this.filterPayload.manCode) return
            try {
                this.isTableLoading = true
                this.$showLoading()
                let response
                if (this.filterPayload.relationMode === 'self') {
                    response = await this.$getResponse(
                        this.filterPayload,
                        this.permission,
                        getReportContestPersonalAPI
                    )
                } else {
                    response = await this.$getResponse(
                        this.filterPayload,
                        this.permission,
                        getReportContestOrgAPI
                    )
                }
                this.listData = response.data?.dataSource ?? []
                this.header = response.data?.header ?? []
            } catch (error) {
                // 後端回 errorCode 為 20001 表示查詢區間查無此人，前端直接當作無業績資料處理
                this.listData = []
            } finally {
                this.isTableLoading = false
                this.$hideLoading()
            }
        },
        downloadAllCompanyInfo: async function () {
            if (!this.hasDownloadAllPermission) return
            if (this.allCompanyReportState === 'loading') return

            const link = document.createElement('a')
            let currentContestID = { contestId: +this.filterPayload.contestId }
            function findMaxID(idList) {
                return Math.max(...idList)
            }
            try {
                this.allCompanyReportState = 'loading'
                let reportStateResponse = await postBackstageGenerateReportAPI(
                    currentContestID
                )
                this.allCompanyReportState = reportStateResponse.data.msg
                if (this.allCompanyReportState === 'success') {
                    let reportInfoResponse = await getBackstageReportInfosAPI()
                    let reportList = reportInfoResponse.data.excelInfos

                    let idList = reportList.map((list) => list.id)
                    let latestListID = findMaxID(idList)
                    let downloadLinkResponse = await getBackstageDownloadReportAPI(
                        latestListID
                    )
                    link.href = downloadLinkResponse.data.urls
                    document.body.append(link)
                    link.click()
                    link.remove()
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.allCompanyReportState = 'uninstall'
            }
        }
    },
    computed: {
        isSelf: function () {
            return this.filterLog.relationMode?.value === 'self'
        },
        hasDownloadAllPermission: function () {
            return this.$hasPermission('downloadAll')
        },
        isCeoMode: function () {
            return this.header.isCeoMode ?? false
        },
        searchConditionList: function () {
            const commonInfo = {
                title: '競賽區間',
                value: `${this.contestInfo?.startDate?.slice(
                    0,
                    10
                )} 至 ${this.contestInfo?.endDate?.slice(0, 10)}`
            }

            const selfInfo = {
                title: `個人總${this.contestInfo.scoreTitle}`,
                value: `${this.header.score}`
            }
            const orgInfo = {
                title: `主管獎總${this.contestInfo.scoreTitle}`,
                value: `${this.header.score}`
            }

            const infos = [commonInfo]
            if (this.isSelf) infos.push(selfInfo)
            else if (!this.isCeoMode) infos.push(orgInfo)
            return infos
        },
        permission: function () {
            let permission = {
                apiAlias: '',
                apiName: ''
            }
            permission.apiName = this.isSelf ? '組織競賽報表' : '競賽報表'
            permission.apiAlias = this.isSelf ? 'orgList' : 'personalList'
            return permission
        }
    },
    watch: {
        allCompanyReportState: {
            handler(state) {
                if (state === 'loading') {
                    const currentContestTitle = this.filterPayload.title
                    this.allCompanyReportWord = `競賽:${currentContestTitle} 報表產生中...`
                    this.allCompanyReportStyle = 'process-loading-icon'
                } else {
                    this.allCompanyReportWord = '全公司競賽業績下載'
                    this.allCompanyReportStyle = 'download-excel-btn'
                }
            }
        }
    },
    data() {
        return {
            isTableLoading: false,
            allCompanyReportState: 'uninstall',
            allCompanyReportWord: '全公司競賽業績下載',
            allCompanyReportStyle: 'download-excel-btn',
            allCompanyReportLink: '',
            filterPayload: {
                titleType: '',
                manCode: '',
                role: '',
                bgCode: '',
                relationMode: '',
                contestId: '',
                year: ''
            },
            filterLog: {},
            listData: [],
            header: {},
            contestInfo: {}
        }
    }
}
</script>

<style lang="scss" scoped>
.table-remark {
    min-height: 20px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    color: $secondary-grey;
    font-size: 14px;
    span {
        margin-right: 10px;
    }

    @media screen and (max-width: 576px) {
        flex-wrap: wrap;
        margin-bottom: 15px;
    }
}
</style>
